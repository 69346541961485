import React from "react"

import HeaderBar from "../../components/HeaderBar"
import PromoContainer from "./PromoContainer"
import AdsContainer from "./AdsContainer"
import AdsQuoteContainer from "./AdsQuoteContainer"
import RoadmapContainer from "./RoadmapContainer"
import FooterBar from "../../components/FooterBar"

import "./index.css"

const LandingPage = () => {
  return (
    <div className="LandingPage PromoContainer bg-cover-image" style={{ backgroundImage: `url("sb_cover.png")` }}>
      <HeaderBar />
      <div className="LandingPageComponent" style={{ minHeight: '500px', height: 'auto' }}>
        <PromoContainer />
        {/* <AdsContainer />
        <AdsQuoteContainer /> */}
      </div>
      <div style={{ backgroundColor: '#000339' }}>
        <RoadmapContainer />
        <FooterBar />
      </div>

    </div>
  )
}

export default LandingPage