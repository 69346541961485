import React from 'react';
import Layer from '../nftLayer';

const Project = ({ nftProject }) => (
    <div className="nft-project card-border flex flex-col" style={{ marginTop: "24px" }}>

        <div className="flex flex-row justify-between align-item-center w-full">
            <div className="flex flex-col justify-start text-left">
                <div className="text-xl bold">Project Dashboard</div>
                <div className="text-l">Noted: All upper layers should have transparent background</div>
            </div>
            <div className="flex">

                <button className="btn-primary-light">Add Layer</button>
            </div>
        </div>
        {
            nftProject && nftProject.layers && nftProject.layers.length > 0 && nftProject.layers.map((nftLayer) => {
                return <Layer nftLayer={nftLayer} />
            })
        }

    </div>
)

export default Project;
