import React from "react"
import Image from "@material-tailwind/react/Image"

import "./index.css"

const NftCard = (props) => {
  return (
    <div className="card-container">
      <Image
        style={{ objectFit: "contain" }}
        className="image-box"
        src={props.url}
        width={250}
        height={250}
        rounded={false}
        raised={false}
      />
      <div className="address-text">
        Owned by {props.address}
      </div>
    </div>
  )
}

export default NftCard