import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Button from "@material-tailwind/react/Button"
import Icon from "@material-tailwind/react/Icon"
import Modal from "@material-tailwind/react/Modal"
import ModalHeader from "@material-tailwind/react/ModalHeader"
import ModalBody from "@material-tailwind/react/ModalBody"
import ModalFooter from "@material-tailwind/react/ModalFooter"
import Textarea from "@material-tailwind/react/Textarea"

import API from "../../../apis"

import "./index.css"

const DashboardHeaderContainer = () => {
  const [showModal, setShowModal] = useState(false)
  const [collectionName, setCollectionName] = useState()
  const [description, setDescription] = useState()
  const [website, setWebsite] = useState()
  const [collectionSymbol, setCollectionSymbol] = useState()

  let history = useHistory()

  const createProject = async () => {
    const { statusCode } = await API.createProject({ name: collectionName, description, website, collection_symbol: collectionSymbol })

    if (statusCode === 200) {
      setShowModal(false)
    } else {
      localStorage.clear()
      history.push("/")
      window.alert("please login.")
    }
  }

  return (
    <div className="DashboardHeaderContainer">
      <div className="DashboardHeaderNameContainer" style={{ fontWeight: "bold" }}>Project Dashboard</div>
      <div className="AddProjectContainer">
        <Button
          style={{
            borderRadius: "4px",
            backgroundColor: "#5F67EC",
            color: "#ffffff",
            background: "rgb(95,103,236)",
            background: "linear-gradient(90deg, rgba(95,103,236,1) 0%, rgba(27,40,255,1) 100%)",
          }}
          color="lightBlue"
          buttonType="filled"
          size="regular"
          rounded={false}
          block={false}
          iconOnly={false}
          ripple="light"

          onClick={() => setShowModal(true)}
        >
          <Icon name="add" size="sm" /> Add
        </Button>
      </div>

      <Modal size="max-w-3xl" active={showModal} toggler={() => setShowModal(false)} style={{ maxWidth: '500px' }}>
        <ModalHeader toggler={() => setShowModal(false)} style={{ fontSize: "20px" }}>
          Project Detail
        </ModalHeader>
        <ModalBody>
          <div className="InputContainer">
            <div id="InputTopic">Project Title</div>
            <Textarea style={{ marginBottom: "16px" }}

              className="text-input rounded-none"
              borderRadius="0"
              color="blueGray"
              size="sm"
              outline={true}
              placeholder="Title"
              value={collectionName}
              onChange={(e) => setCollectionName(e.target.value)}
            />
          </div>
          <div className="InputContainer">
            <div id="InputTopic">Collection Symbol (Optional)</div>
            <Textarea
              color="blueGray"
              size="sm"
              outline={true}
              placeholder="Collection Symbol e.g. BAYC for Bored Ape Yacht Club, Ͼ for Crypto Punk"
              value={collectionSymbol}
              onChange={(e) => setCollectionSymbol(e.target.value)}
            />
          </div>
          <div className="InputContainer">
            <div id="InputTopic">Description (Optional)</div>
            <Textarea style={{ marginBottom: "16px" }}
              color="blueGray"
              size="sm"
              outline={true}
              placeholder="Brief Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          <div className="InputContainer">
            <div id="InputTopic">Website URL</div>
            <Textarea
              color="blueGray"
              size="sm"
              outline={true}
              placeholder="Website URL"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>

          {/* {textField.map(i => <TextInputContainer name={i.name} placeHolder={i.placeHolder} />)} */}
        </ModalBody>
        <ModalFooter>
          <Button
            style={{
              width: "100%",
              borderRadius: "4px",
              backgroundColor: "#5F67EC",
              color: "#ffffff",
              background: "rgb(95,103,236)",
              background: "linear-gradient(90deg, rgba(95,103,236,1) 0%, rgba(27,40,255,1) 100%)",
            }}
            color="blue"
            onClick={async () => await createProject()}
            ripple="light"
          >
            Create
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DashboardHeaderContainer