const axios = require('axios')

const API = {}

// const BACKEND_URL = 'https://4ct0x51n5e.execute-api.ap-southeast-1.amazonaws.com/dev'
const BACKEND_URL = 'https://api.speedboat.studio'

const parseResultFromBackend = (result) => {
  const statusCode = result.data.statusCode
  const body = JSON.parse(result.data.body)

  return { statusCode, body }
}

API.createProject = async ({ name, description, website, collection_symbol }) => {
  try {
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/project`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        name,
        description,
        website,
        collection_symbol
      }
    })
    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, project: body }
  } catch(err) {
    console.log("create project error =>", err)
  }
}

API.listProject = async (status) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/projects`,
      params: {
        status
      },
      headers: {
        'auth-token': localStorage.getItem("token")
      }
    })
    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, projects: body }
  } catch(err) {
    console.log("list project error =>", err)
  }
}

API.findOrCreateUser = async (token) => {
  try {
    let address
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/auth`,
      headers: {
        'auth-token': token
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    if (statusCode === 200) {
      address = body.address
    }

    return address
  } catch(err) {
    console.log("get user error =>", err)
  }
}

API.uploadImage = async (project_id, layer_name, filenames) => {
  try {
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/project/layer/image`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
        layer_name,
        filenames,
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch (err) {
    console.log("upload image error =>", err)
  }
}

API.uploadS3 = async (url, fields, file) => {
  try {
    const formData = new FormData();
    for (let key in fields) {
      formData.append(key, fields[key]);
    }
    formData.append("file", file)
    const result = await axios({
      method: 'post',
      url: url,
      data: formData
    })

    return result
  } catch (err) {
    console.log("upload s3 error =>", err)
  }
}

API.createLayer = async (project_id, layer_name) => {
  try {
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/project/layer`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
        layer_name,
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch (err) {
    console.log("create layer error =>", err)
  }
}

API.getProject = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/project/${id}`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.updateProjectDetail = async (project_id, price, royalty_fee, n_target_nft) => {
  try {
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/project/nft_details`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
        price,
        royalty_fee,
        n_target_nft,
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.updateRarity = async (project_id, layer_name, filename, n) => {
  try {
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/project/layer/image/rarity`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
        layer_name,
        filename,
        n,
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.deleteLayer = async (project_id, layer_name) => {
  try {
    const result = await axios({
      method: 'delete',
      url: `${BACKEND_URL}/project/layer`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
        layer_name,
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.deleteImage = async (project_id, layer_name, filename) => {
  try {
    const result = await axios({
      method: 'delete',
      url: `${BACKEND_URL}/project/layer/image`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
        layer_name,
        filename
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.finalizeProject = async (project_id) => {
  try {
    const result = await axios({
      method: 'post',
      url: `${BACKEND_URL}/project/nft_properties`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
      data: {
        project_id,
      }
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.getSampleImage = async (project_id) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/sample_image/${project_id}`,
      headers: {
        'auth-token': localStorage.getItem("token")
      },
    })

    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.getSampleImagePublic = async (project_id) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/${project_id}/sample_image`,
    })

    return { statusCode: result.statusCode, data: result.data }
  } catch(err) {
    console.log("get project error =>", err)
  }
}

API.getSellProject = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/${id}/public_info`
    })

    const status = result.status
    const project = result.data

    return { status, project }
  } catch(err) {
    console.log("get sell project error =>", err)
  }
}

API.getSellProjectPicture = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/${id}/sample_image`
    })

    const status = result.status
    const nfts = result.data

    return { status, nfts }
  } catch(err) {
    console.log("get sell project error =>", err)
  }
}

API.getDeployParam = async (id) => {
  try {
    const result = await axios({
      method: 'get',
      url: `${BACKEND_URL}/${id}/deploy_param`,
      headers: {
        'auth-token': localStorage.getItem("token")
      }
    })
console.log('deploy result ==> ', result)
    const { statusCode, body } = parseResultFromBackend(result)

    return { statusCode, data: body }
  } catch (err) {
    console.log("deploy project error =>", err)
  }
}

export default API
