import React from 'react';
import Card from '../nftCard';

const Layer = ({ nftLayer }) => (<div className="card-border flex flex-col align-item-center" style={{ marginTop: "16px" }}>
    <div className="flex justify-between w-full">
        <div>{nftLayer.name}</div>
        <a className="underline">Remove</a>
    </div>
    <div className="hz-container" style={{ marginTop: "16px" }}>
        <div className='nft-layer flex-scroll'>
            {
                nftLayer && nftLayer.cards && nftLayer.cards.length > 0 && nftLayer.cards.map((card) => {
                    return card && <Card nftCard={card} />
                })
            }
        </div>
    </div>
    <button className="btn-primary-light w-full"> Upload</button>
</div>
);

export default Layer;
