import React from 'react';
import PageCard from '../nftSaleCard';

const Layout = ({ nftLayout }) => (
    <div className='grid-3'>
        {
            nftLayout && nftLayout.cards && nftLayout.cards.length > 0 && nftLayout.cards.map((card) => {
                return card && <PageCard saleCard={card} />
            })
        }
    </div>

);

export default Layout;
