import React from 'react';

const ProfileHeader = ({ pageHeader }) => {
    const buyNFT = () => {
        window.buyNFT();
    };
    return pageHeader ? <div className="flex flex-col justify-center w-30 m-auto" style={{ height: "fit-content" }} id='Header'>
        <div className="avatar m-auto" style={{ marginBottom: "16px" }}></div>
        <div className="text-xl bold m-auto" style={{ marginBottom: "16px" }}>{pageHeader.title}</div>
        <div className="text-l m-auto" style={{ marginBottom: "16px" }}>{pageHeader.desc}</div>
        <button onClick={() => { buyNFT() }} className="btn-primary m-auto" style={{ marginBottom: "16px" }}>Mint</button>
        <div className="text-l" >20 of 4,000 Available</div>
    </div> : <div></div>
};

export default ProfileHeader;
