import React from "react"

import HeaderBar from "../../components/HeaderBar"
import DashboardHeaderContainer from "./DashboardHeaderContainer"
import DashboardContentContainer from "./DashboardContentContainer"
import FooterBar from "../../components/FooterBar"

import "./index.css"

const ProjectDashboardPage = () => {
  return (
    <div className="ProjectDashboardPage">
      <HeaderBar />
      <div className="ProjectDashboardPageComponent">
        <DashboardHeaderContainer />
        <DashboardContentContainer />
      </div>
      <FooterBar />
    </div>
  )
}

export default ProjectDashboardPage