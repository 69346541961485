import React from "react"
import Image from "@material-tailwind/react/Image"
import Button from "@material-tailwind/react/Button"
import Icon from "@material-tailwind/react/Icon";
import Input from "@material-tailwind/react/Input"
import Modal from "@material-tailwind/react/Modal"
import ModalHeader from "@material-tailwind/react/ModalHeader"
import ModalBody from "@material-tailwind/react/ModalBody"
import ModalFooter from "@material-tailwind/react/ModalFooter"
import API from '../../../apis'

import "./index.css"

class NftLayer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      uploading: false, // Track uploading status
      createLayerModal: false,
      layerName: ""
    }
    this.fileInputs = [] // Store all input for file selecting in each layer
  }

  async handleInputChange(index) {
    // Get all files from the input
    const files = this.fileInputs[index].files

    // Return if the user open file selection and then close it without selecting files
    if (files.length < 1) return;

    // Disable all upload buttons
    this.setState({ uploading: true })

    // Send API to get S3 pre-signed URL
    const layerName = this.props.layers[index].name
    const { projectId } = this.props


    let filenames = []
    for (let i = 0; i < files.length; i++) {
      filenames.push(files[i].name)
    }

    let uploadResult
    try {
      const { data } = await API.uploadImage(projectId, layerName, filenames)
      uploadResult = data
    } catch (e) {
      this.setState({ uploading: false })
      return;
    }

    // Upload new files to S3
    for (let i = 0; i < uploadResult.s3_presigned_urls.length; i++) {
      const { url, fields } = uploadResult.s3_presigned_urls[i]
      const s3 = await API.uploadS3(url, fields, files[i])
    }

    // Wait for the upload to finish
    const uploadPromises = uploadResult.s3_presigned_urls.map((s3_presigned_url, i) => {
      const { url, fields } = s3_presigned_url
      return API.uploadS3(url, fields, files[i])
    })
    await Promise.all(uploadPromises)

    // Unlock upload buttons and refresh project data
    await this.props.refreshData()
    this.setState({ uploading: false })
  }

  async deleteLayer(name) {
    const { projectId } = this.props
    await API.deleteLayer(projectId, name)
    await this.props.refreshData()
  }

  getLayer(layer, i) {
    return (
      <div className="layer " key={layer.name}>
        {this._getLayerHeader(layer.name, i + 1)}
        <div className="hz-container">
          <div className="layer-card-container flex-scroll nft-layer">
            {layer.items.map((item, j) => (
              <div className="card nft-card nft-attribute" key={item.name}>
                <div className="card-title">
                  <div>{item.name}</div>
                  <Button style={{
                    color: '#bdbdbd', backgroundColor: 'transparent', boxShadow: 'none'
                  }}
                    className="delete-button"
                    color="lightBlue"
                    buttonType="filled"
                    size="sm"
                    rounded={true}
                    block={false}
                    iconOnly={true}
                    ripple="light"
                    onClick={() => this.props.deleteHandler(i, j)}
                  >
                    <Icon name="delete" size="sm" />
                  </Button>
                </div>
                <Image
                  className="sell-image-box img-container-l"
                  width={190}
                  height={190}
                  src={item.url}
                  rounded={false}
                  raised={false}
                />
              </div>
            ))}
            <input type="file"
              onChange={e => this.handleInputChange(i)}
              ref={input => this.fileInputs[i] = input}
              className="form-control"
              style={{ display: "none" }}
              multiple
            />
          </div>
        </div>
        <Button className="primary-light-button w-full"
          color="lightBlue"
          buttonType="filled"
          size="sm"
          rounded={false}
          block={false}
          ripple="light"
          disabled={this.state.uploading}
          onClick={() => this.fileInputs[i].click()}
        >
          Upload Image
        </Button>
      </div>
    )
  }

  _getLayerHeader(name, index) {
    return (
      <div className="layer-header">
        Layer {index}: {name}
        <Button className="text-link"
          color="lightBlue"
          buttonType="filled"
          size="sm"
          rounded={false}
          block={false}
          iconOnly={false}
          ripple="light"
          onClick={() => this.deleteLayer(name)}
        >
          Remove
        </Button>
      </div>
    )
  }

  setShowModal(bool) {
    this.setState({ createLayerModal: bool })
  }

  getModal() {
    const { createLayerModal } = this.state
    return (
      <Modal size="regular" active={createLayerModal} toggler={() => this.setShowModal(false)}>
        <ModalHeader toggler={() => this.setShowModal(false)} style={{ fontSize: "20px" }} >
          Layer Name
        </ModalHeader>
        <ModalBody>
          <Input
            type="text"
            color="lightBlue"
            size="regular"
            outline={true}
            width={300}
            value={this.state.layerName}
            placeholder={"Layer Name"}
            onChange={e => this.setState({ layerName: e.target.value })}
          />
        </ModalBody>
        <ModalFooter>
          <Button className="primary-button"
            color="lightBlue"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            ripple="light"
            onClick={() => this.createNewLayer()}
          >
            Add Layer
          </Button>
        </ModalFooter>
      </Modal>
    )
  }

  async createNewLayer() {
    const { data: createResult } = await API.createLayer(this.props.projectId, this.state.layerName)
    this.setShowModal(false)
    this.setState({ layerName: "" })
    await this.props.refreshData()
  }

  render() {
    return (
      <div className="nft-layer-properties">
        <div className="main-header">
          <div className="bold" style={{ fontSize: '20px' }}>NFT Properties</div>
          <Button className="primary-button"
            color="lightBlue"
            buttonType="filled"
            size="regular"
            rounded={false}
            block={false}
            iconOnly={false}
            ripple="light"
            onClick={() => this.setShowModal(true)}
          >
            Add Layer
          </Button>
        </div>
        {this.props.layers.map((layer, i) => {
          return this.getLayer(layer, i)
        })}
        {this.getModal()}
      </div>
    )
  }
}

export default NftLayer
