import React from 'react'
import { Link } from 'react-router-dom'
import './index.css'
import Project from '../components/nftProject'

function Landing() {
    const doSetSail = () => {
        window.doSetSail()
    }

    const mock_project_data = {
        name: "mock_project",
        layers: [
            {
                name: "Glasses",
                cards: [{
                    name: "card_name_1",
                    img_src: "glasses1.png"
                }, {
                    name: "card_name_2",
                    img_src: "glasses2.png"
                }],
            }, {
                name: "Pug",
                cards: [{
                    name: "card_name_1",
                    img_src: "pug1.png"
                }, {
                    name: "card_name_2",
                    img_src: "pug2.png"
                }, {
                    name: "card_name_3",
                    img_src: "pug3.png"
                }, {
                    name: "card_name_4",
                    img_src: "pug4.png"
                }, {
                    name: "card_name_5",
                    img_src: "pug5.png"
                }],
            }
        ]
    }

    const HeaderLandingComponent = () => {
        return (
            <div className="relative">
                <img src={'herobg.svg'} className="absolute top-0 left-0" />
                <div className="page-width flex flex-col">
                    <div className="font-color-primary text-left text-3xl" style={{ marginTop: "128px" }}>
                        World Finest <br />NFT Generator
                    </div>
                    <div className="font-color-primary text-left text-xl">
                        More than 34k NFTs have been generated here
                    </div>
                    <button className="login-button" style={{ marginTop: "32px" }}> Connect Wallet</button>
                </div>
            </div>
        )
    }

    return (
        <div className="Landing">
            <HeaderLandingComponent />
            <div className=" page-width relative flex justify-between flex-col" style={{ marginTop: "32px" }}>
                <div className="font-color-primary text-left text-xl bold">
                    Project Dashboard
                </div>
                <div className="card-border text-left flex  justify-between align-item-center" style={{ marginTop: "24px", display: "none" }}>
                    <div className="text-xl bold">Project Dashboard</div>
                    <div className="flex">
                        <button className="btn-secondary" style={{ marginRight: "8px" }}> Edit Detail</button>
                        <button className="btn-primary-light"> Upload</button>
                    </div>
                </div>
            </div>

            <div className="page-width" >
                <Project nftProject={mock_project_data} />
                <button onClick={() => doSetSail()} style={{ textDecoration: "none", color: "white", margin: "32px 0" }} className="btn-primary w-full m-auto">Deploy</button>
                <Link to={`/profile#Header`} style={{ display: "none", margin: "32px 0" }} className="btn-primary-light w-full m-auto">Sell Page</Link>
                <a href={`/profile`} style={{ textDecoration: "none", margin: "32px 0" }} className="btn-primary-light w-full m-auto">Sell Page</a>

            </div>

            <div className=" page-width relative flex justify-between flex-col" style={{ marginTop: "32px", display: "none" }}>
                <div className="font-color-primary text-left text-xl bold">
                    Table
                </div>
                <div className="card-border text-left flex  justify-between align-item-center" style={{ marginTop: "24px" }}>
                    <table class="unstyledTable">
                        <thead>
                            <tr>
                                <th>Attribute</th>
                                <th>Rarity</th>
                                <th>Probability</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td>Glass1</td>
                                <td><div class="custom-select">
                                    <select>
                                        <option value="0">Common</option>
                                        <option value="1">Uncommon</option>
                                        <option value="2">Rare</option>
                                        <option value="3">Super Rare</option>
                                    </select>
                                </div></td>
                                <td>2/10000</td>
                            </tr>
                            <tr>
                                <td>Glass2</td>
                                <td><div class="custom-select">
                                    <select>
                                        <option value="0">Common</option>
                                        <option value="1">Uncommon</option>
                                        <option value="2">Rare</option>
                                        <option value="3">Super Rare</option>
                                    </select>
                                </div></td>
                                <td>1000/10000</td>
                            </tr>
                            <tr>

                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className=" page-width relative flex justify-between flex-col" style={{ marginTop: "32px", display: "none" }}>
                <div className="font-color-primary text-left text-xl bold">
                    Input
                </div>
                <div className="card-border text-left flex  flex-col" style={{ marginTop: "24px" }}>
                    <label className="text-xl bold" style={{ marginBottom: "16px" }}>Project Title</label>
                    <input className="input-box" style={{ marginBottom: "24px" }} placeholder="Text" />

                    <label className="text-xl bold" style={{ marginBottom: "16px" }}>Project Description</label>
                    <textarea className="textarea-box" style={{ marginBottom: "24px" }} placeholder="Text"></textarea>
                </div>
            </div>
            <div className=" page-width relative flex justify-between flex-col" style={{ display: "none" }}>
                <div className="font-color-primary text-left text-xl bold">
                    Profile Page Section
                </div>
            </div>
        </div>
    )
}

export default Landing
