import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import HeaderBar from "../../components/HeaderBar"
import FooterBar from "../../components/FooterBar"
import SellPageContentContainer from "../deploy/SellPageContentContainer"

import Web3Wrapper from "../../apis/web3"
import API from "../../apis"
import Image from "@material-tailwind/react/Image"
import Button from "@material-tailwind/react/Button"
import "./index.css"

const SellPage = () => {
  const params = useParams()

  const [projectInfo, setProjectInfo] = useState({
    title: "",
    profileUrl: "",
    description: "",
    link: "",
    contractAddress: "",
    contractChainId: 0,
    contractMaxSupply: 0,
    contractTotalSupply: 0,
    contractPrice: 0
  })
  const [nftSample, setNftSample] = useState({
    nftSample: null
  })

  const fetch_project_info = async (id) => {
    const { status: sellProjectStatus, project } = await API.getSellProject(id)
    const { status, nfts } = await API.getSellProjectPicture(id)
    const { contractMaxSupply, contractTotalSupply, contractPrice } = await fetch_contract_info(project.chain_id, project.project_address)

    setProjectInfo({
      title: project.name,
      profileUrl: project.thumbnail,
      description: project.description,
      link: project.website,
      contractAddress: project.project_address,
      contractChainId: project.chain_id,
      contractMaxSupply,
      contractTotalSupply,
      contractPrice
    })
  }

  const fetch_contract_info = async (contractChainId, project_address) => {
    let contractMaxSupply = 0
    let contractTotalSupply = 0
    let contractPrice = 0

    await Web3Wrapper.ensureWallet()
    const customerChainId = await Web3Wrapper.getChainId()

    if (customerChainId !== contractChainId) {
      window.alert("Please change chain to Polygon first.")
      return { contractMaxSupply, contractTotalSupply, contractPrice }
    }

    const contract = await Web3Wrapper.connectContract(project_address)
    contractMaxSupply = await contract.methods.maxSupply().call()
    contractTotalSupply = await contract.methods.totalSupply().call()
    contractPrice = await contract.methods.price().call()
    return { contractMaxSupply, contractTotalSupply, contractPrice }
  }

  useEffect(() => {
    fetch_project_info(params.id)
  }, [])


  // const nftList = [
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  //   {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  // ]

  const mint = async () => {
    const customerChainId = await Web3Wrapper.getChainId()

    if (customerChainId !== projectInfo.contractChainId) {
      window.alert("Please change chain to Polygon first.")
      return
    }

    const contract = await Web3Wrapper.connectContract(projectInfo.contractAddress)
    const customerAddress = await Web3Wrapper.getAddress()
    const result = await contract.methods.buy().send({ from: customerAddress, value: projectInfo.contractPrice })

    alert("minted")
  }

  const fetchRandomImage = async () => {
    const result = await API.getSampleImagePublic(params.id)
    setNftSample({ nftSample: result.data.image_url })
  }


  return (
    <div className="SellPage">
      <HeaderBar />
      <div className="SellPageComponent">
        <SellPageContentContainer info={projectInfo} images={[]} mintHandler={mint} />
        <Button className="primary-light-button w-full"
          color="lightBlue"
          buttonType="filled"
          size="sm"
          rounded={false}
          block={false}
          ripple="light"
          onClick={fetchRandomImage.bind(this)}
        >
          Preview NFT
        </Button>
        <div style={{ display: "flex", width: "100%" }}>
          <Image className="w-full"
            style={{ marginTop: "20px" }}
            className="sell-image-box"
            width={190}
            height={190}
            src={nftSample.nftSample}
            rounded={false}
            raised={false}
          />
        </div>
      </div>
      <FooterBar />
    </div>
  )
}

export default SellPage