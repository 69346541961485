import React from 'react';
import ProfileHeader from '../profileHeader';
import Layout from '../gridLayout';

const mock_page_header = {
    title: "test",
    desc: "desc"
}

const mock_project_data = {
    name: "mock_project",
    layers: [
        {
            name: "layer_1",
            cards: [{
                name: "card_name_1",
                img_src: "fpug1.png"
            }, {
                name: "card_name_2",
                img_src: "fpug2.png"
            }, {
                name: "card_name_3",
                img_src: "fpug3.png"
            }, {
                name: "card_name_4",
                img_src: "fpug4.png"
            }, {
                name: "card_name_5",
                img_src: "fpug5.png"
            }, {
                name: "card_name_6",
                img_src: "fpug6.png"
            }],
        }, {
            name: "layer_1",
            cards: [{
                name: "card_name_1",
                img_src: "http://test.com"
            }, {
                name: "card_name_2",
                img_src: "http://test.com"
            }, {
                name: "card_name_3",
                img_src: "http://test.com"
            }, {
                name: "card_name_4",
                img_src: "http://test.com"
            }, {
                name: "card_name_5",
                img_src: "http://test.com"
            }, {
                name: "card_name_6",
                img_src: "http://test.com"
            }],
        }
    ]
};

const ProfilePage = () => {
    return <div className="nft-project card-border flex flex-col relative top-0" style={{}}>
        <ProfileHeader pageHeader={mock_page_header} />
        <Layout nftLayout={mock_project_data.layers[0]} />
    </div>;
};

export default ProfilePage;
