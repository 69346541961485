import React from "react"
import { useHistory } from "react-router-dom"
import Button from "@material-tailwind/react/Button"

import Web3Wrapper from "../../apis/web3"
import API from "../../apis"

const MyProjectsButton = () => {
  let history = useHistory()

  const navigate = async () => {
    const token = localStorage.getItem("token")

    if (token != undefined) {
      history.push("/dashboard")
    } else {
      const token = await Web3Wrapper.generateToken()
      const address = await API.findOrCreateUser(token)

      if (address) {
        localStorage.setItem("token", token)
        history.push("/dashboard")
			} else {
				window.alert("Please Try Again.")
			}
    }
  }

  return (
    <Button style={{
      borderRadius: "4px",
      backgroundColor: "#5F67EC",
      color: "#ffffff",
      background: "rgb(95,103,236)",
      background: "linear-gradient(90deg, rgba(95,103,236,1) 0%, rgba(27,40,255,1) 100%)",
    }}
      color="blueGray"
      buttonType="filled"
      size="regular"
      rounded={false}
      block={false}
      iconOnly={false}
      ripple="light"

      onClick={() => navigate()}
    >
      Create
    </Button>
  )
}

export default MyProjectsButton