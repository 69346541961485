import React from "react"

import "./index.css"

const FooterBar = () => {
  return (
    <div className="Footer-Bar-Container align-end width-control w-full">
      <div className="Footer-Information-Container">
        <div className="">Speedboat Inc. 2021</div>
      </div>
    </div>
  )
}

export default FooterBar