import React from 'react';

const PageCard = ({ saleCard }) => (
    saleCard && <div className="nft-card">

        <img className="img-container-l" src={saleCard.img_src} />

    </div>
);

export default PageCard;
