import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Button from "@material-tailwind/react/Button"
import Dropdown from "@material-tailwind/react/Dropdown"
import DropdownItem from "@material-tailwind/react/DropdownItem"

import API from "../../apis/index"
import Web3Wrapper from "../../apis/web3"

const ConnectWalletButton = () => {
	const [loading, setLoading] = useState(false)
	const [onLogin, setOnlogin] = useState(false)

	let history = useHistory()

	const navigate = (path) => {
		history.push(path)
	}

	const keepSession = (token) => {
		localStorage.setItem("token", token)
		setOnlogin(true)
	}

	const logout = () => {
		localStorage.clear()
		setOnlogin(false)
	}

	// const handleAuthenticate = async ({ publicAddress, signature }) => {
	// 	try {
	// 		const response = await fetch(`${process.env.BACKEND_URL}/auth`, {
	// 			body: JSON.stringify({ publicAddress, signature }),
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 			method: 'POST',
	// 		})

	// 		return response.json()
	// 	} catch (err) {
	// 		throw new Error(
	// 			'Got some occurs from back end server when authen.'
	// 		)
	// 	}
	// }

	// const handleSignMessage = async ({ publicAddress, nonce }) => {
	// 	try {
	// 		const signature = await web3.eth.personal.sign(
	// 			`I am signing my one-time nonce: ${nonce}`,
	// 			publicAddress,
	// 			''
	// 		)

	// 		return { publicAddress, signature }
	// 	} catch (err) {
	// 		throw new Error(
	// 			'You need to sign the message to be able to log in.'
	// 		)
	// 	}
	// }

	// const handleSignup = async (publicAddress) => {
	// 	try {
	// 		const response = await fetch(`${process.env.BACKEND_URL}/users`, {
	// 			body: JSON.stringify({ publicAddress }),
	// 			headers: {
	// 				'Content-Type': 'application/json',
	// 			},
	// 			method: 'POST',
	// 		})

	// 		return response.json()
	// 	} catch (err) {
	// 		throw new Error(
	// 			'Got some occurs from back end server when sign up.'
	// 		)
	// 	}
	// }

	const login = async () => {
		try {
			const token = await Web3Wrapper.generateToken()
			// const publicAddress = coinbase.toLowerCase()
			setLoading(true)
			const address = await API.findOrCreateUser(token)

			if (address) {
				setLoading(false)
				keepSession(token)
			} else {
				window.alert("Please Try Again.")
				setLoading(false)
			}
		} catch (err) {
			window.alert(err)
			setLoading(false)
		}


		// TODO: try to connnect real backend and test it
		// Look if user with current publicAddress is already present on backend
		// fetch(
		// 	`${process.env.REACT_APP_BACKEND_URL}/users?publicAddress=${publicAddress}`
		// )
		// 	.then((response) =>
		// 		response.json()
		// 	)
		// 	// If yes, retrieve it. If no, create it.
		// 	.then((users) =>
		// 		console.log("user ==>", users)
		// 		// users.length ? users[0] : handleSignup(publicAddress)
		// 	)
		// Popup MetaMask confirmation modal to sign message
		// .then(handleSignMessage)
		// Send signature to backend on the /auth route
		// .then(handleAuthenticate)
		// Pass accessToken back to parent component (to save it in localStorage)
		// .then(keepSession)
		// .catch((err) => {
		// 	window.alert(err)
		// 	setLoading(false)
		// })
	}

	return (
		<div>
			{onLogin ?
				<Dropdown style={{
					height: "36px",
					borderRadius: "4px",
					backgroundColor: "#5f67ec29",
					color: "#5f67ec"
				}}
					color="lightBlue"
					placement="bottom-start"
					buttonText="Your Page"
					buttonType="filled"
					size="regular"
					rounded={false}
					block={false}
					ripple="light"
				>
					{/* <DropdownItem  color="lightBlue" ripple="light" onClick={() => navigate("/dashboard")}>
						My Page
					</DropdownItem> */}
					<DropdownItem color="lightBlue" ripple="light" onClick={() => navigate("/dashboard")} className="dropdown-btn">
						Project Dashboard
					</DropdownItem>
					<DropdownItem color="lightBlue" ripple="light" onClick={() => logout()} className="dropdown-btn">
						Logout
					</DropdownItem>
				</Dropdown> :
				<Button style={{
					borderRadius: "4px",
					backgroundColor: "#5F67EC29",
					color: "#5F67EC"
				}}
					color="lightBlue"
					buttonType="filled"
					size="regular"
					rounded={false}
					block={false}
					iconOnly={false}
					ripple="light"

					onClick={login}
				>
					{loading ? "Loading" : "Connect"}
				</Button>
			}
		</div>
	)
}

export default ConnectWalletButton