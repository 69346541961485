import React from 'react';

const Card = ({ nftCard }) => (
    nftCard && <div className="nft-card attribute-card" key={nftCard.name}>

        <div className="flex justify-between w-full">
            <div>{nftCard.img_src}</div>
            <a className="underline">Remove</a>
        </div>
        <img className="img-container" src={nftCard.img_src} />

    </div>
);

export default Card;
