import React, { useEffect, useState } from "react"

import HeaderBar from "../../components/HeaderBar"
import FooterBar from "../../components/FooterBar"
import StatusHeader from "./StatusHeader"
import SellPageContentContainer from "./SellPageContentContainer"

import Web3Wrapper from "../../apis/web3"
import API from "../../apis"

import "./index.css"

const DeployPage = () => {
  const [price, setPrice] = useState(0)
  const [ maxSupply, setMaxSupply ] = useState(0)
  const [ totalSupply, setTotalSupply ] = useState(0)

  const contractAddress = "0x2A749306e540d148C8B3833E5dAF7aaEAb5Fe43a"

  const fetch_contract_info = async (address) => {
    await Web3Wrapper.generateToken()
    const customerChinId = await Web3Wrapper.getChainId()

    if (customerChinId !== Web3Wrapper.contractChainId) {
      window.alert("Please change chain to Polygon first.")
      return
    }

    const contract = await Web3Wrapper.connectContract(address)
    const contractMaxSupply = await contract.methods.maxSupply().call()
    const contractTotalSupply = await contract.methods.totalSupply().call()
    const contractPrice = await contract.methods.price().call()

    setMaxSupply(maxSupply)
    setTotalSupply(totalSupply)
    setPrice(contractPrice)

    return
  }

  useEffect(() => {
    fetch_contract_info(contractAddress)
  }, [])

  const isProjectReady = true;
  const projectDeployHandler = async () => {
    const id = "d8e28b04-1dcc-455d-8c4d-5b8e2a334a17"
    const { statusCode, data } = await API.deployProject(id)
    console.log('handler ==> ', statusCode, data)

    

    alert('Deployed')
  }

  const nftList = [
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
    {url: "https://i.imgur.com/BKsGPGJ.gif", address: "0x2a98f128092aBBadef25d17910EbE15B8495D0c1"},
  ]

  const profileInfo = {
    title: "Project Title",
    profileUrl: "https://i.imgur.com/KPqTbuZ.png",
    description: "Polypugs laucnehd as a fixed set of 391 items on Ethereum. Each pug has unique attributes which could ocntianed up to 6 attributes. Polypugs index site is on the way!",
    links: [
      "https://twitter.com/FF_XIV_EN",
      "https://discord.gg/yjUKAPV6",
      "https://www.instagram.com/ffxiv"
    ]
  }

  const mint = async () => {
    const customerChinId = await Web3Wrapper.getChainId()

    if (customerChinId !== Web3Wrapper.contractChainId) {
      window.alert("Please change chain to Polygon first.")
      return
    }

    const contract = await Web3Wrapper.connectContract(contractAddress)
    const customerAddress = await Web3Wrapper.getAddress()
    const result = await contract.methods.buy().send({ from: customerAddress, value: price })

    alert("minted")
  }


  return (
    <div className="DeployPage">
      <HeaderBar />
      <div className="SellPageComponent">
        <StatusHeader isReady={true} onClick={projectDeployHandler}/>
        <SellPageContentContainer info={profileInfo} images={nftList} mintHandler={mint}/>
      </div>
      <FooterBar />
    </div>
  )
}

export default DeployPage