import { BrowserRouter, Route, Switch } from 'react-router-dom';
import "@material-tailwind/react/tailwind.css";

import './App.css';
import Landing from './landing';
import LandingPage from './pages/landing';
import ProfilePage from './components/profilePage'
import ProjectDashboardPage from './pages/projectDashboard'
import DeployPage from './pages/deploy'
import SellPage from './pages/sell'
import UploadPage from './pages/upload';

function App() {
  return (
    <div className="App">
      <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />
      <BrowserRouter>
        <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route exact path="/profile">
              <ProfilePage />
            </Route>
            <Route exact path="/dashboard">
              <ProjectDashboardPage />
            </Route>
            <Route exact path="/project/:id" component={UploadPage} />
            <Route exact path="/legacy">
              <Landing />
            </Route>
            <Route exact path="/sell">
              <DeployPage />
            </Route>
            <Route exact path="/sell/:id">
              <SellPage />
            </Route>
          </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
