import React from "react"
import Navbar from "@material-tailwind/react/Navbar";
import NavbarContainer from "@material-tailwind/react/NavbarContainer";
import Button from "@material-tailwind/react/Button"

import "./index.css"

const StatusHeader = (props) => {

  const getStatusText = (isReady) => {
    if (isReady) {
      return "Your project is ready to deploy"
    } else {
      return "Your NFT project is now in progress..."
    }
  }

  return (
    <div style={{ width: "100%" }}>
      <Navbar className="status-bar" color="lightBlue" navbar>
        <NavbarContainer>
          <div>{getStatusText(props.isReady)}</div>
          {
            props.isReady ? <Button

              style={{
                borderRadius: "4px",
                backgroundColor: "#5F67EC",
                color: "#ffffff",
              }}
              buttonType="filled"
              size="lg"
              rounded={false}
              block={false}
              iconOnly={false}
              ripple="light"
              display={!props.isReady}
              onClick={props.onClick}
            >
              Deploy
            </Button> : <div></div>
          }
        </NavbarContainer>
      </Navbar>
    </div>
  )
}

export default StatusHeader