import React from "react"

import "./index.css"

const RoadmapContainer = () => {
  return (
    <div className="RoadmapContainer width-control w-full relative">
      <div className="RoadmapContentContainer">
        <div id="RoadmapTitle" style={{ color: '#FF553E' }}>Our Roadmap</div><br />
        <div id="RoadmapContent">
          <div style={{ marginBottom: '24px' }}>
            <div className="bold" style={{ marginBottom: '8px' }}>
              First Phase - All-in-one NFT Generation Platform & Sale Page
            </div>
            <ul style={{ marginBottom: '24px', fontSize: '20px' }}>
              <li>◆ Customizable attributes, layer, and rarity via a GUI</li>
              <li>◆ Personalized sale page for creators</li>
              <li>◆ Gas-optimized NFT contract deployment</li>
              <li>◆ Compatible with Polygon, Ethereum, BSC and Fantom</li>
            </ul>
          </div>
          <div style={{ marginBottom: '24px' }}>
            <div className="bold" style={{ marginBottom: '8px' }}>
              Second Phase - More Complex NFT Experiences
            </div>
            <ul style={{ marginBottom: '24px', fontSize: '20px' }}>
              <li>◆ Loot boxes & Gacha</li>
              <li>◆ Breeding & Evolution</li>
            </ul>
          </div>
          <div style={{ marginBottom: '24px' }}>
            <div className="bold" style={{ marginBottom: '8px' }}>
              Third Phase - Strong Infrastructure & Integration Throughout Metaverse
            </div>
            <ul style={{ marginBottom: '24px', fontSize: '20px' }}>
              <li>◆ A full composability with other projects</li>
              <li>◆ Customizable code for core components </li>
              <li>◆ Integration support for NFT GameFi and DeFi</li>

            </ul>
          </div>

        </div>

      </div>
      <div className="blur-light"></div>
    </div>
  )
}

export default RoadmapContainer