import React, { useState, useEffect } from "react"
import Button from "@material-tailwind/react/Button"
import Image from "@material-tailwind/react/Image"
import "./index.css"
import { SocialIcon } from 'react-social-icons';
import NftCard from "../nftCard";
import { propTypes } from "react-bootstrap/esm/Image";

const SellPageConentContainer = (props) => {

	return (
		<div className="SellPageConentContainer">
			<div className="profile">
				<div className="profile-pic-container">
					<div className="profile-pic-element">
						{/* <Image
							src={props.info.profileUrl}
							width={120}
							rounded={true}
							raised={false}
							alt="Rounded Image"
						/> */}
					</div>
					<div className="profile-pic-element">
						{/* <Button
						color="lightBlue"
						buttonType="filled"
						size="sm"
						rounded={true}
						block={false}
						iconOnly={false}
						ripple="light"
					>
						Edit Project
					</Button> */}
					</div>
				</div>
				<div className="project-overview">
					<div className="project-title">{props.info.title}</div>
					<div className="project-description">{props.info.description}</div>
				</div>
				<Button
					style={{
						borderRadius: "4px",
						backgroundColor: "#5F67EC",
						color: "#ffffff",

					}}
					color="lightBlue"
					buttonType="filled"
					size="lg"
					rounded={false}
					block={false}
					iconOnly={false}
					ripple="light"
					onClick={props.mintHandler}
				>
					Mint
				</Button>
				<div className="availability">
					{props.info.contractMaxSupply - props.info.contractTotalSupply} of {props.info.contractMaxSupply} available
				</div>
				<div className="social-container">
					<SocialIcon className="social-button" url={props.info.link} />
				</div>
			</div>
			<div className="nft">
				{/* <div className="nft-properties">
					<Button
						className="properties-button"
						buttonType="filled"
						size="sm"
						rounded={true}
						block={false}
						iconOnly={false}
						ripple="light"
					>
						Trait
					</Button>
					<Button
						className="properties-button"
						buttonType="filled"
						size="sm"
						rounded={true}
						block={false}
						iconOnly={false}
						ripple="light"
					>
						Rarity
					</Button>
					<Button
						className="properties-button"
						buttonType="filled"
						size="sm"
						rounded={true}
						block={false}
						iconOnly={false}
						ripple="light"
					>
						Roadmap
					</Button>
				</div>
				<hr /> */}
				<div className="nft-title" style={{ display: "none" }}>Your NFT</div>
				<div className="nft-cards">
					{props.images.map(nft => (
						<NftCard
							url={nft.url}
							address={nft.address}
						/>
					))}
				</div>
			</div>
		</div >
	)
}

export default SellPageConentContainer