import React from "react"

import MyProjectsButton from "../myProjectsButton"
import ConnectWalletButton from "../connectWalletButton"

import logo from "../../assets/logo192.png"

import "./index.css"

const HeaderBar = () => {

  return (
    <div className="Header-Bar-Container align-item-center width-control">
      <div className="Header-Logo-Container">
        <img src={'/speedboat_logo.png'} style={{ height: "55px", objectFit: "contain" }} className="hidden-sm" />
        <img src={'/speedboat_logo_mini.png'} style={{ height: "55px", objectFit: "contain" }} className="hidden-md" />
      </div>
      <div className="Header-Action-Container">
        <MyProjectsButton />
        <ConnectWalletButton />
      </div>
    </div>
  )
}

export default HeaderBar
