import React from 'react';

import HeaderBar from "../../components/HeaderBar"
import FooterBar from "../../components/FooterBar"
import Input from "@material-tailwind/react/Input"
import Button from "@material-tailwind/react/Button"
import Checkbox from "@material-tailwind/react/Checkbox"
import Image from "@material-tailwind/react/Image"
import Web3Wrapper from "../../apis/web3"

import "./index.css"
import NftLayer from './nftLayer';
import API from '../../apis'


class UploadPage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      projectId: "",
      name: "",
      description: "",
      price: 0,
      royalty_fee: 0,
      target: 1,
      layers: [
      ],
      loading: false,
      autoRandom: false,
      probabilities: {
      },
      sample_image: [
      ]
    }
  }

  async componentDidMount() {
    await this.fetchProjectData()
    await Web3Wrapper.ensureWallet()

  }

  async fetchRandomImage() {
    const result = await API.getSampleImage(this.props.match.params.id)
    if (result) {
      let sample_image = Object.assign(this.state.sample_image)
      if (sample_image.length === 3) {
        sample_image.shift()
      }
      sample_image.push(result.data.image_url)
      this.setState(sample_image)
    }
  }

  async fetchProjectData() {
    const { data } = await API.getProject(this.props.match.params.id)
    console.log(data)

    let probabilities = {}
    const layers = data.layer_priority.map(layerName => {
      probabilities[layerName] = {}
      const items = Object.entries(data.layers[layerName]).map(component => {
        if (component[1].n) {
          probabilities[layerName][component[0]] = component[1].n
        }
        else {
          probabilities[layerName][component[0]] = 0
        }

        return {
          name: component[0],
          url: component[1].img_path
        }
      })

      return {
        name: layerName,
        items
      }
    })

    this.setState({
      projectId: data.id,
      name: data.name,
      description: data.description,
      target: data.n_target_nft,
      layers: layers.reverse(),
      price: data.price,
      royalty_fee: data.royalty_fee,
      probabilities,
    })

    // Fetch 3 sample
    await this.fetchRandomImage()
    await this.fetchRandomImage()
    await this.fetchRandomImage()
  }

  async imageDeleteHandler(layerIndex, imageIndex) {
    const { projectId } = this.state
    let layers = [...this.state.layers]
    await API.deleteImage(
      projectId,
      layers[layerIndex].name,
      layers[layerIndex].items[imageIndex].name,
    )
    layers[layerIndex].items.splice(imageIndex, 1);
    this.setState({ layers })
  }

  getProbabilityLayer(layer) {
    return layer.items.map(item => this.getProbabilityItem(layer, item))
  }

  getProbabilityItem(layer, item) {
    return (
      <tr key={item.name}>
        <td>{item.name}</td>
        <td><Input style={{ width: '160px', backgroundColor: '#ffffff' }} className="w-20"
          type="text"
          color="lightBlue"
          size="regular"
          outline={true}
          value={this.state.probabilities[layer.name][item.name]}
          disabled={this.state.autoRandom}
          onChange={e => {
            var probabilities = this.state.probabilities
            probabilities[layer.name][item.name] = e.target.value
            this.setState({ probabilities: probabilities })
          }}
          placeholder={"Probability"}
        /></td>
        <td> of {this.state.target}</td>
      </tr>
    )
  }

  async toggleAutoRandom() {
    if (this.state.target == 0) return;
    await this.setState({ autoRandom: !this.state.autoRandom })
    const target = this.state.target
    let probabilities = Object.assign(this.state.probabilities)

    // Assign value when user ticks the checkbox to auto randomized value
    if (this.state.autoRandom) {
      let probabilities = Object.assign(this.state.probabilities)
      for (let property in probabilities) {
        const length = Object.entries(probabilities[property]).length
        let index = 0
        for (let name in probabilities[property]) {
          if (index < length - 1) {
            probabilities[property][name] = Math.floor(target / length)
          } else {
            probabilities[property][name] = target - Math.floor(target / length) * (length - 1)
          }
          index += 1
        }
      }
      this.setState({ probabilities })
    }
  }

  async saveDraft() {
    const { projectId, price, royalty_fee, target, probabilities } = this.state
    this.setState({ loading: true })
    await API.updateProjectDetail(projectId, price, royalty_fee, target)
    for (let property in probabilities) {
      for (let name in probabilities[property]) {
        const n = probabilities[property][name]
        await API.updateRarity(projectId, property, name, n)
      }
    }
    this.setState({ loading: false })
  }

  async finalizeProject() {
    await this.saveDraft()
    await API.finalizeProject(this.state.projectId)

    this.props.history.push(`/sell/${this.state.projectId}`)
  }

  async deploy() {
    //   const customerChinId = await Web3Wrapper.getChainId()
    // if (customerChinId !== 137) {
    //   window.alert("Please change chain to Polygon first.")
    //   return
    // }

    // @dev deploy new NFT + sell. basic
    // @param name NFT name (721 standard)
    // @param symbol NFT symbol (721 standard)
    // @param baseTokenURI NFT baseTokenURI (721 standard)
    // @param price - selling price per NFT. in wei
    // @param _maxSupply - max NFT to be sell
    // @param _beneficiary - who t ake the mooney. (can be user's or contract e.g. payment splitter)
    var projectParams = await API.getDeployParam(this.state.projectId)
    projectParams = projectParams["data"]
    var name = projectParams["name"]
    var symbol = projectParams["symbol"]
    var baseTokenURI = projectParams["baseTokenURI"]
    var price = projectParams["price"]
    var maxSupply = projectParams["_maxSupply"]
    var beneficiary = projectParams["_beneficiary"]
    const contract = await Web3Wrapper.connectShipyard()
    const customerAddress = await Web3Wrapper.getAddress()
    const result = await contract.methods.setSail(
      name,
      symbol,
      baseTokenURI,
      price,
      maxSupply,
      beneficiary
    ).send({ from: customerAddress })
  }

  render() {
    const { layers } = this.state
    return (
      <div className="UploadPage">
        <HeaderBar />
        <div className="UploadPageComponent">
          <div className="title">{this.state.name}</div>
          <div className="description">{this.state.description}</div>
          <div className="form InputContainer">
            <div className="bold" style={{ fontSize: '20px' }}>Unit Price for Each NFT</div>
            <Input
              type="text"
              color="black"
              size="regular"
              value={this.state.price}
              onChange={e => this.setState({ price: e.target.value })}
              outline={true}
              placeholder={"Unit Price Currency"}
            />
            <div className="bold" style={{ fontSize: '20px' }}>Percentage Fee</div>
            <Input
              type="text"
              color="black"
              size="regular"
              value={this.state.royalty_fee}
              onChange={e => this.setState({ royalty_fee: e.target.value })}
              outline={true}
              placeholder={"Percentage"}
            />
            <div className="bold" style={{ fontSize: '20px' }}>Total Amount of NFT</div>
            <Input
              type="text"
              color="black"
              size="regular"
              value={this.state.target}
              onChange={e => this.setState({ target: e.target.value })}
              outline={true}
              placeholder={"Amount"}
            />
          </div>

          <NftLayer
            projectId={this.state.projectId}
            layers={this.state.layers}
            deleteHandler={this.imageDeleteHandler.bind(this)}
            refreshData={this.fetchProjectData.bind(this)}
          />

          <div >
            <div className="bold" style={{ fontSize: '20px' }}>Rarity Setting</div>
            <Checkbox
              color="lightBlue"
              text="Enable Auto Randomize All"
              id="checkbox"
              value={this.state.autoRandom}
              disabled={this.state.target == 0}
              onChange={() => this.toggleAutoRandom()}
            />
            <table className="w-full">
              <thead>
                <tr className="w-full">
                  <th>Attribute</th>
                  <th>Probability</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {layers.map(layer => this.getProbabilityLayer(layer))}
              </tbody>
            </table>
          </div>

          <div>
            <div className="w-full main-header" style={{ margin: '24px 0' }}>
              <div className="bold" style={{ fontSize: '20px' }}>NFT Preview</div>
              <Button className="primary-light-button"
                color="lightBlue"
                buttonType="filled"
                size="sm"
                rounded={false}
                block={false}
                ripple="light"
                disabled={this.state.loading}
                onClick={this.fetchRandomImage.bind(this)}
              >
                Randomize Preview
              </Button>
            </div>
            <div style={{ display: "flex" }}>
              {this.state.sample_image.reverse().map((sample, i) => (
                <Image
                  key={sample + "-" + i}
                  style={{ marginRight: "20px" }}
                  className="sell-image-box"
                  width={190}
                  height={190}
                  src={sample}
                  rounded={false}
                  raised={false}
                />
              ))}
            </div>
          </div>

          <Button className="w-full primary-light-button" style={{ margin: '24px 0' }}
            color="lightBlue"
            buttonType="filled"
            size="sm"
            rounded={false}
            block={false}
            ripple="light"
            disabled={this.state.loading}
            onClick={this.saveDraft.bind(this)}
          >
            Save Draft
          </Button>
          {this.state.status != "PUBLISHED" ?
            <Button className="w-full primary-button"
              color="lightBlue"
              buttonType="filled"
              size="sm"
              rounded={false}
              block={false}
              ripple="light"
              disabled={this.state.loading}
              onClick={this.finalizeProject.bind(this)}
            >
              Finalize and Publish
            </Button>
            :
            <Button className="w-full"
              color="red"
              buttonType="filled"
              size="sm"
              rounded={false}
              block={false}
              ripple="light"
              disabled={this.state.loading}
              onClick={this.deploy.bind(this)}
            >
              Deploy!
            </Button>
          }

        </div>
        <FooterBar />
      </div>
    )
  }
}

export default UploadPage
