import React from "react"
import "./index.css"

const PromoContainer = () => {
  return (
    <div className="PromoContainer width-control w-full">
      <div className="PromoHeaderContainer text-gradient">World Finest<br />NFT Generator</div>
      <div className="PromoDetailContainer">More than 34k NFTs have been generated here</div>
    </div>
  )
}

export default PromoContainer