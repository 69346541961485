import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import Button from "@material-tailwind/react/Button"

import ProjectCardContainer from "../../../components/projectCardContainer"

import API from '../../../apis'

import "./index.css"

const DashboardContentContainer = () => {
	const [openTab, setOpenTab] = useState("ALL")
	const [content, setContent] = useState(Array)

	let history = useHistory()

	const fetch_data = async (filterStatus) => {
		let status

		if (filterStatus !== "ALL") {
			status = filterStatus
		}

		const token = localStorage.getItem("token")
		const { statusCode, projects } = await API.listProject(status)

		if (statusCode === 200) {
			setOpenTab(filterStatus)
			setContent(projects)
			setOpenTab(filterStatus)
		} else {
			localStorage.clear()
			history.push("/")
			window.alert("please login.")
		}
	}

	useEffect(() => {
		fetch_data(openTab)
	}, [])

	return (
		<div className="DashboardContentContainer">
			<div className="DashboardContentTabContainer">
				<Button
					style={{
						borderRadius: "40px",
						backgroundColor: "#5F67EC29",
						color: "#5F67EC"
					}}
					color="gray"
					buttonType="filled"
					size="sm"
					rounded={true}
					block={false}
					iconOnly={false}
					ripple="light"

					onClick={async () => await fetch_data()}
				>
					All
				</Button>
				<Button
					style={{
						borderRadius: "40px",
						backgroundColor: "#EAEAEA",
						color: "#000000"
					}}
					color="gray"
					buttonType="filled"
					size="sm"
					rounded={true}
					block={false}
					iconOnly={false}
					ripple="light"

					onClick={async () => await fetch_data("DRAFT")}
				>
					Draft
				</Button>
				<Button
					style={{
						borderRadius: "40px",
						backgroundColor: "#EAEAEA",
						color: "#000000"
					}}
					color="gray"
					buttonType="filled"
					size="sm"
					rounded={true}
					block={false}
					iconOnly={false}
					ripple="light"

					onClick={async () => await fetch_data("PUBLISHED")}
				>
					Published
				</Button>
			</div>

			<div className="DashboardContentListContainer">
				{content.map(data =>
					<ProjectCardContainer
						id={data.id}
						name={data.name}
						status={data.status}
						total={data.total}
						rest={data.rest}
					/>
				)
				}
			</div>
		</div>
	)
}

export default DashboardContentContainer