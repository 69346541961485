'use stirct'

import React from "react"
import { useHistory } from "react-router-dom"
import Button from "@material-tailwind/react/Button"

import "./index.css"

const PUBLISHED_STATUS = "published"

const ProjectCardContainer = ({ id, name, status, total, rest }) => {
  let history = useHistory()

  const fetchProject = (id) => {
    history.push(`/project/${id}`)
  }

  return (
    <div className="ProjectCardContainer">
      <div className="ProjectDescriptionContainer">
        <div className="ProjectNameAndStatusContainer">
          <div id="ProjectName" style={{ color: "#000000", fontWeight: "bold" }}>{name}</div>
          <div id="ProjectStatus">{status}</div>
        </div>
        <div className="ProjectQuantityContainer">
          {status === PUBLISHED_STATUS ?
            <div id="ProjectQuantity" style={{ color: "#000000", opacity: "0.4" }}>{rest}/{total} Available</div> :
            <div></div>
          }
        </div>
      </div>
      <div className="ProjectActionContainer">
        <Button
          style={{
            borderRadius: "4px",
            backgroundColor: "#5F67EC29",
            color: "#5F67EC"
          }}
          color="lightBlue"
          buttonType="filled"
          size="regular"
          rounded={false}
          block={false}
          iconOnly={false}
          ripple="light"
          value={id}

          onClick={(e) => fetchProject(e.target.value)}
        >
          Manage NFT
        </Button>
      </div>
    </div>
  )
}

export default ProjectCardContainer